import { IconType } from 'react-icons'
import { CgFileDocument } from 'react-icons/cg'
import { FaFileInvoiceDollar, FaFileSignature } from 'react-icons/fa'
import { FaFileContract } from 'react-icons/fa6'
import { HiOutlineDocumentReport } from 'react-icons/hi'
import { RiCharacterRecognitionLine } from 'react-icons/ri'
import { BiSpreadsheet, BiBuildingHouse } from 'react-icons/bi'
import { SiRobotframework } from 'react-icons/si'
import { MdAttachMoney, MdChecklist, MdArticle, MdAdminPanelSettings, MdAccountBalanceWallet, MdReceiptLong, MdAnalytics, MdEmojiEvents } from 'react-icons/md'

import { SCOPE, STAGE } from '../../config/env'

const resolvedStage = (() => {
  const stage = STAGE.toLowerCase()
  switch (stage) {
    case 'prod':
      return ''
    default:
      return `${stage}-`
  }
})()

const resolvedScope = (() => {
  const scope = SCOPE.toLowerCase()
  switch (scope) {
    case 'atrio':
      return 'atrioasset'
    case 'sulbrasil':
      return 'sbcredito'
    default:
      return scope
  }
})()

const allModules: Array<{
  title: string
  description: string
  icon: IconType
  location: string
  group: string
}> = [
  {
    title: 'Dossiê',
    description: 'No dossiê você consegue ver os processos, dívidas, patrimônios, embargos, infrações, offshores, PEP e muito mais.',
    icon: CgFileDocument,
    group: 'boanota_front',
    location: `https://analise.${resolvedStage}${resolvedScope}.boanota.credit/dossie`
  },
  {
    title: 'Smart Assistant',
    description: 'No smart assistant você poderá enviar arquivos de matrícula, contrato social, faturamento e petição inicial para análise.',
    icon: SiRobotframework,
    group: 'boanota_front_smart_assistant',
    location: `https://smart-assistant.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'Análise Provisionamento',
    description: 'Aqui você poderá analisar o provisionamento dos fundos.',
    icon: MdAnalytics,
    group: 'boanota_front_analise_provisionamento',
    location: `https://analise-provisionamento.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'Aprovação do CNAB',
    description: 'Aqui você poderá analisar os arquivos CNAB enviados pelos consultores.',
    icon: FaFileInvoiceDollar,
    group: 'boanota_front_aprovacao_cnab',
    location: `https://aprovacao-cnab.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'Geração de CNABs',
    description: 'Aqui você poderá fazer upload das notas fiscais para geração de arquivos CNABs.',
    icon: MdReceiptLong,
    group: 'boanota_front_geracao_cnab',
    location: `https://geracao-cnab.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'Envio do CNAB',
    description: 'Aqui você poderá fazer o upload de arquivos CNAB para aprovação do gestor.',
    icon: FaFileInvoiceDollar,
    group: 'boanota_front_upload_cnab',
    location: `https://upload-cnab.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'Carteira, Estoque e TLB',
    description: 'Aqui você poderá fazer o download dos dados de carteira, estoque e TLB.',
    icon: HiOutlineDocumentReport,
    group: 'boanota_front_repositorio',
    location: `https://repositorio.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'SPED',
    description: 'Módulo para processamento do ECD e EFD.',
    icon: FaFileContract,
    group: 'boanota_front_sped',
    location: `https://sped.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'OCR',
    description: 'Módulo para processamento de imagem do balanço patrimonial ou DRE.',
    icon: RiCharacterRecognitionLine,
    group: 'boanota_front_ocr',
    location: `https://ocr.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'Spread',
    description: 'No módulo de spread poderá criar e acessar spreads individuais e de grupos.',
    icon: BiSpreadsheet,
    group: 'boanota_front_spread',
    location: `https://spread.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'Visitas',
    description: 'No módulo de visitas para geração de relatórios para análise de crédito',
    icon: BiBuildingHouse,
    group: 'boanota_front_visitas',
    location: `https://visitas.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'Cadastro de cedente',
    description: 'Aqui você pode cadastrar cedentes para geração de relatórios para análise de crédito',
    icon: MdChecklist,
    group: 'boanota_front_cadastro_cedente',
    location: `https://cadastro-cedente.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'Aprovação de cedentes',
    description: 'No módulo de aprovação de cedente você avaliará as solicitações dos cedentes',
    icon: FaFileSignature,
    group: 'boanota_front_aprovacao_cedente',
    location: `https://aprovacao-cedente.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'DRE',
    description: 'No módulo para geração de DRE',
    icon: MdAccountBalanceWallet,
    group: 'boanota_front_geracao_dre',
    location: `https://geracao-dre.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'Recebíveis',
    description: 'No módulo para análise de recebíveis',
    icon: MdAttachMoney,
    group: 'boanota_front_analise_recebiveis',
    location: `https://analise-recebiveis.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'Inadimplência',
    description: 'No módulo para análise de inadimplência',
    icon: MdAttachMoney,
    group: 'boanota_front_analise_inadimplencia',
    location: `https://analise-inadimplencia.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'Ranking de concentrações',
    description: 'Aqui você verá o ranking de concentrações sacados e cedentes.',
    icon: MdEmojiEvents,
    group: 'boanota_front_ranking_concentracoes',
    location: `https://ranking-concentracoes.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'Relatório',
    description: 'Página de relatório para análise do comitê.',
    icon: MdArticle,
    group: 'boanota_front_relatorio',
    location: `https://relatorio.${resolvedStage}${resolvedScope}.boanota.credit`
  },
  {
    title: 'Administração',
    description: 'Página de administração do sistema. Nele você pode criar e gerenciar usuários, grupos e permissões.',
    icon: MdAdminPanelSettings,
    group: 'boanota_front_gestao_usuarios',
    location: `https://gestao-usuarios.${resolvedStage}${resolvedScope}.boanota.credit`
  }

]

export default allModules
